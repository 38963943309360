import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import useSiteMetadata from '../components/SiteMetadata';
import MetaComponent from '../components/MetaComponent';

export const CheckInPageTemplate = ({ helmet, pageData }) => (
  <div className='contact-page-container container content'>
    {helmet || ''}
    <div>
      <iframe
        src={pageData.iframe_source}
        style={{
          position: 'relative',
          minWidth: '100%',
          width: '100%',
        }}
        frameBorder='0'
        scrolling='yes'
        seamless='seamless'
        height='483'
        title='Check In'
        width='100%'></iframe>
    </div>
  </div>
);

const CheckInPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;
  const { title, siteUrl } = useSiteMetadata();
  return (
    <Layout>
      <CheckInPageTemplate
        location={location}
        pageData={frontmatter}
        helmet={
          <MetaComponent
            title={`${frontmatter.seoObject.metaTitle}`}
            canonical={`${siteUrl}${location.pathname}`}
            siteName={`${title}`}
            description={`${frontmatter.seoObject.metaDescription}`}
            link={`${siteUrl}${location.pathname}`}
          />
        }
      />
    </Layout>
  );
};

export default CheckInPage;

export const pageQuery = graphql`
  query CheckInPageQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "check-in-page" } }) {
      frontmatter {
        iframe_source
        seoObject {
          metaTitle
          metaDescription
        }
      }
    }
  }
`;
